import {lazy} from 'react'
import {createBrowserRouter} from 'react-router-dom'
import {App} from '../App'

import {MasterLayout} from '../layout/MasterLayout'
import {PrivateRoute} from './PrivateRoutes'

const AuthPage = lazy(() => import('../app/modules/auth/AuthPage'))
const DashboardWrapper = lazy(() => import('../app/pages/dashboard/DashboardWrapper'))

const AccountPage = lazy(() => import('../app/modules/accounts/AccountPage'))
const WidgetsPage = lazy(() => import('../app/modules/widgets/WidgetsPage'))

/// Category Routes
const AddCategory = lazy(() => import('../app/modules/Menu/categories/AddMenuCategory'))
const CategoryList = lazy(() => import('../app/modules/Menu/categories/ListMenuCategory'))
const UpdateCategory = lazy(() => import('../app/modules/Menu/categories/UpdateCategories'))
const UpdateCategoryImage = lazy(() => import('../app/modules/Menu/categories/UpdateCategoryImage'))

///Drinks Routes
const DrinkList = lazy(() => import('../app/modules/Menu/items/ListDrink'))
/// Food Routes
const FoodList = lazy(() => import('../app/modules/Menu/items/ListFood'))
/// Cocktail Routes
const CocktailList = lazy(() => import('../app/modules/Menu/items/ListCocktail'))
/// Easter Routes
const EasterList = lazy(() => import('../app/modules/Menu/items/ListEaster'))

/// Menu Item common Routes
const AddMenuItem = lazy(() => import('../app/modules/Menu/items/AddMenuItem'))
const UpdateMenuitem = lazy(() => import('../app/modules/Menu/items/UpdateMenuItem'))
const UpdateMenuitemImage = lazy(() => import('../app/modules/Menu/items/UpdateItemImage'))

/// Table Routes
const TableList = lazy(() => import('../app/modules/tables/ListTables'))
const AddTable = lazy(() => import('../app/modules/tables/AddTable'))
const UpdateTable = lazy(() => import('../app/modules/tables/UpdateTable'))

/// Reservation Routes
const ReservationList = lazy(() => import('../app/modules/reservations/ListReservation'))
const SingleReservation = lazy(() => import('../app/modules/reservations/SingleReservation'))

/// hero Image
const HeroImages = lazy(() => import('../app/modules/hero-slider/ListHeroSlider'))
const AddHeroImages = lazy(() => import('../app/modules/hero-slider/AddHeroSlider'))

/// Banner Image
const BannerImages = lazy(() => import('../app/modules/banner-slider/ListBannerSlider'))
const AddBannerImages = lazy(() => import('../app/modules/banner-slider/AddBannerSlider'))

/// Gallery Image
const GalleryImages = lazy(() => import('../app/modules/Gallery/ListImageSlider'))
const AddGalleryImages = lazy(() => import('../app/modules/Gallery/AddGalleryImage'))

/// contact form
const ContactForm = lazy(() => import('../app/modules/contact-form/ListContactForm'))
const SingleContactForm = lazy(() => import('../app/modules/contact-form/SingleContactForm'))

/// Analytics Routes
const ListVisitor = lazy(() => import('../app/modules/analytics/ListVisitors'))
const SingleVisitor = lazy(() => import('../app/modules/analytics/VisitorDetail'))

/// Error Page
const ErrorPage = lazy(() => import('../app/modules/errors/ErrorsPage'))

const router = createBrowserRouter([
  {
    path: '',
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: (
          <PrivateRoute>
            <MasterLayout />
          </PrivateRoute>
        ),
        children: [
          {path: '', element: <DashboardWrapper />},
          {
            path: 'menu/category-list',
            element: <CategoryList />,
          },
          {path: 'menu/category-list/add', element: <AddCategory />},
          {path: 'menu/category-list/update/:id', element: <UpdateCategory />},
          {path: 'menu/category-list/update/image/:id', element: <UpdateCategoryImage />},

          /// Drinks Routes
          {
            path: 'menu/drink-list',
            element: <DrinkList />,
          },
          /// Food Routes
          {
            path: 'menu/food-list',
            element: <FoodList />,
          },

          /// cocktail Routes
          {
            path: 'menu/cocktail-list',
            element: <CocktailList />,
          },

          /// Easter Routes
          {
            path: 'menu/easter-list',
            element: <EasterList />,
          },

          /// Menu Item common Routes
          {path: 'menu/item/add', element: <AddMenuItem />},
          {path: 'menu/item/update/:id', element: <UpdateMenuitem />},
          {path: 'menu/item/image/update/:id', element: <UpdateMenuitemImage />},

          /// Table Routes
          {
            path: 'tables/list',
            element: <TableList />,
          },
          {
            path: 'tables/add',
            element: <AddTable />,
          },
          {
            path: 'tables/update/:id',
            element: <UpdateTable />,
          },
          /// Reservation Routes
          {
            path: 'reservation/list',
            element: <ReservationList />,
          },
          {
            path: 'reservation/:id',
            element: <SingleReservation />,
          },

          /// Hero Image
          {
            path: '/hero-images',
            element: <HeroImages />,
          },
          {
            path: '/hero-images/add',
            element: <AddHeroImages />,
          },

          /// Banner Image
          {
            path: '/banner-images',
            element: <BannerImages />,
          },
          {
            path: '/banner-images/add',
            element: <AddBannerImages />,
          },
          ///Gallery Images

          {
            path: '/gallery-images',
            element: <GalleryImages />,
          },
          {
            path: '/gallery-images/add',
            element: <AddGalleryImages />,
          },

          /// contact form
          {
            path: '/contact-form',
            element: <ContactForm />,
          },
          {
            path: '/contact-form/:id',
            element: <SingleContactForm />,
          },
          /// Analytics Routes
          {
            path: '/analytics',
            element: <ListVisitor />,
          },
          {
            path: '/analytics/:id',
            element: <SingleVisitor />,
          },

          /// Additional Routes
          {path: 'account', element: <AccountPage />},

          {path: 'widgets', element: <WidgetsPage />},
        ],
      },
      {
        path: 'auth',
        element: <AuthPage />,
        children: [{path: 'login', element: <AuthPage />}],
      },
      {
        path: '/',
        element: <MasterLayout />,
        children: [
          {
            path: 'dashboard',
            element: (
              <PrivateRoute>
                <DashboardWrapper />
              </PrivateRoute>
            ),
          },
        ],
      },
    ],
  },
])

export {router}
