import {createContext, useContext, useEffect, useState} from 'react'
import {UserModel} from '../models/auth/auth.model'

export interface AuthContextType {
  user: UserModel | null
  setUser: React.Dispatch<React.SetStateAction<UserModel | null>>
  logout: () => void
  checkLogin: () => void
  loading: boolean
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  buttonLoading: boolean
  setButtonLoading: React.Dispatch<React.SetStateAction<boolean>>
}

const AuthContext = createContext<AuthContextType | null>(null)

const useAuth = () => useContext(AuthContext)

const AuthProvider = ({children}: {children: React.ReactNode}) => {
  const [user, setUser] = useState<UserModel | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [buttonLoading, setButtonLoading] = useState<boolean>(false)

  const checkLogin = () => {
    return true
  }

  const logout = () => {
    localStorage.removeItem('token')
  }

  useEffect(() => {
    checkLogin()
  }, [])

  const contextValue: AuthContextType = {
    logout,
    user,
    setUser,
    loading,
    setLoading,
    checkLogin,
    buttonLoading,
    setButtonLoading,
  }

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
}

export {AuthProvider, useAuth}
