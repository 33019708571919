/* eslint-disable react/jsx-no-target-blank */

import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuMain = () => {
  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='element-11'
        title='Dashboard'
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/menu/category-list'
        title='Menu Categories'
        fontIcon='bi-archive'
        icon='abstract-45'
      />
      <SidebarMenuItem
        to='/menu/food-list'
        title='Menu Items'
        fontIcon='bi-archive'
        icon='abstract-49'
      />
      {/* <SidebarMenuItemWithSub to='/menu' title='Menu' fontIcon='bi-archive' icon='abstract-45'>
        <SidebarMenuItem to='/menu/food-list' title='Food' hasBullet={true} />
        <SidebarMenuItem to='/menu/drink-list' title='Drinks' hasBullet={true} />
        <SidebarMenuItem to='/menu/cocktail-list' title='Cocktails and Lemonade' hasBullet={true} />
        <SidebarMenuItem to='/menu/easter-list' title='Easter Menu' hasBullet={true} />
      </SidebarMenuItemWithSub> */}

      <SidebarMenuItem to='/tables/list' title='Tables' icon='abstract-48' fontIcon='bi-archive' />

      <SidebarMenuItem
        to='/reservation/list'
        title='Reservation'
        icon='abstract-44'
        fontIcon='bi-archive'
      />

      <SidebarMenuItem
        to='/hero-images'
        title='Hero Slider'
        icon='abstract-43'
        fontIcon='bi-archive'
      />

      <SidebarMenuItem
        to='/banner-images'
        title='Banner Slider'
        icon='abstract-42'
        fontIcon='bi-archive'
      />
      <SidebarMenuItem
        to='/gallery-images'
        title='Gallery Images'
        icon='abstract-43'
        fontIcon='bi-archive'
      />

      <SidebarMenuItem
        to='/contact-form'
        title='Contact Form'
        icon='abstract-9'
        fontIcon='bi-archive'
      />
      <SidebarMenuItem to='/analytics' title='Analytics' icon='abstract-12' fontIcon='bi-archive' />
    </>
  )
}

export {SidebarMenuMain}
