import {createRoot} from 'react-dom/client'

import {QueryClient, QueryClientProvider} from 'react-query'

import './assets/fonticon/fonticon.css'
import './assets/keenicons/duotone/style.css'
import './assets/keenicons/outline/style.css'
import './assets/keenicons/solid/style.css'

import {RouterProvider} from 'react-router-dom'
import './assets/sass/plugins.scss'
import './assets/sass/style.react.scss'
import './assets/sass/style.scss'
import {AuthProvider} from './context/AuthContext'
import {router} from './routes/AppRoutes'

// Chart.register(...registerables)

const queryClient = new QueryClient()
const container = document.getElementById('root')
if (container) {
  createRoot(container).render(
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <RouterProvider router={router} />
      </AuthProvider>
    </QueryClientProvider>
  )
}
