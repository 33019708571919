import {FC, Suspense} from 'react'
import Cookies from 'universal-cookie'
import {Navigate} from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../assets/ts/_utils'
import {WithChildren} from '../helpers'

export const PrivateRoute = ({children}: any) => {
  // const {user} = useAuth() as AuthContextType
  // const {checkLogin} = useAuth() as AuthContextType
  // const token = localStorage.getItem('token')
  const cookies = new Cookies(null, {path: '/'})
  const token = cookies.get('token')

  // checkLogin()

  if (token) {
    return <SuspensedView>{children}</SuspensedView>
  }

  return <Navigate to='/auth' />
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}
