import {Suspense} from 'react'
import {Outlet} from 'react-router-dom'
import {MasterInit} from './layout/MasterInit'
import {LayoutProvider, LayoutSplashScreen} from './layout/core'
import {ThemeModeProvider} from './partials'

const App = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <LayoutProvider>
        <ThemeModeProvider>
          <Outlet />
          <MasterInit />
        </ThemeModeProvider>
      </LayoutProvider>
    </Suspense>
  )
}

export {App}
